/** @format */

import React from "react";
import GamePlay from "../components/elements/GamePlay";
import Hero from "../components/elements/Hero";
import HowToPlay from "../components/elements/HowToPlay";
import OurTeam from "../components/elements/OurTeam";
import Partners from "../components/elements/Partners";
import RoadMap from "../components/elements/RoadMap";
import Sec1 from "../components/elements/Sec1";
import Tokenomics from "../components/elements/Tokenomics";
import Videos from "../components/elements/Videos";
import Carousal from "../components/utils/Carousal";
import FAQs from "../components/utils/FAQs";
import Loading from "../components/utils/Loading";

export default function Home() {
  return (
    <>
      {/* <Loading /> */}
      <Hero />
      <Sec1 />
      <GamePlay />
      <HowToPlay />
      <Tokenomics />
      <RoadMap />
      {/* <OurTeam/> */}

      {/* <Carousal/> */}
      <Partners />
      <FAQs />
      <Videos />
    </>
  );
}
