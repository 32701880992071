/** @format */

import React from "react";

export default function RoadMap() {
  return (
    <div className='container-fluid roadmap-bg section-padding' id='roadmap'>
      <div className='row'>
        <div className='text-center'>
          <h1 className='res-text' data-aos='fade-right'>
            Road Map
          </h1>
          <p>
            Our team is comprised of passionate professionals who excel at their
            specialties. Atop this <br className='line-break' /> foundation of
            teamwork and passion, we deliver excellent project results.
          </p>
        </div>
        <div className='col-md-4 col-8'>
          <div className='row'>
            <div className='col-md-6 text-end col-2'>
              <img src={"/assets/Group 24.svg"} alt='' />
            </div>
            <div className='col-md-6 col-10 roadmap-bullets'>
              <h2 className=''>Q1, 2024</h2>
              <p>· Idea development</p>
              <p>· NFTs utility formation</p>
              <p>· Research and development</p>{" "}
              <p>· On boarding team members</p>
              <p>· NFT design and development</p>
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <img
            src={"/assets/img.svg"}
            alt=''
            className='res-img road-map-img'
          />
        </div>
        <div className='col-md-4 col-8'>
          <div className='row'>
            <div className='col-md-2 col-2'>
              <img src={"/assets/Group 24.svg"} alt='' />
            </div>
            <div className='col-md-6 col-10 roadmap-bullets'>
              <h2 className=''>Q2, 2024</h2>
              <p>· Documentation release</p>
              <p>· 1st phase of NFT utility testnet</p>
              <p>· beta testing for public</p>
              <p>· Web development UX/UI design</p>
              <p>· Strategic partnership</p>
              <p>· Airdrop reward</p>
              <p>· Bounty reward</p>
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-4 m-auto col-6'>
          <div className='row'>
            <div className='col-md-2 col-2'>
              <img src={"/assets/Group 24.svg"} alt='' />
            </div>
            <div className='col-md-6 col-10 roadmap-bullets bullets-res'>
              <h2>Q3, 2024</h2>
              <p>· Team expansion</p>
              <p>· Mobile app set up</p>
              <p>· CEX listing</p>
              <p>· Phase beta testing</p>
              <p>· Mint NFTs</p>
              <p>· Staking reward</p>
              <p>· In-game NFTs</p>
              <p>· Desktop app</p>
              <p>· Airdrop reward</p>
              <p>· Life-time reward NFTs</p>
              <p>· Live NFTs reward dashboard</p>
              <p>· Relase charity funds for NGOs</p>
            </div>
          </div>
        </div>
        <div className='col-md-4 m-auto col-6'>
          <div className='row'>
            <div className='col-md-2 col-2'>
              <img src={"/assets/Group 24.svg"} alt='' />
            </div>
            <div className='col-md-6 col-10 roadmap-bullets bullets-res'>
              <h2 className=''>Q4, 2024</h2>
              <p>. Documentation release</p>
              <p>· Gaming touranments</p>
              <p>· Advance marketing plan</p>
              <p>· Bounty reward deistribution</p>
              <p>· NGO affiliation</p>
              <p>· Airdrop reward</p>
              <p>· Mobile testnet beta version</p>
              <p>· Mobile mainnet for public</p>
              <p>· Cross-chain integration</p>
            </div>
          </div>
        </div>
        <div className='col-md-4 m-auto col-6'>
          <div className='row'>
            <div className='col-md-2 col-2'>
              <img src={"/assets/Group 24.svg"} alt='' />
            </div>
            <div className='col-md-6 col-10 roadmap-bullets bullets-res'>
              <h2 className=''>Q1, 2024</h2>

              <p>· Bug Bounty competition</p>
              <p>· R&D expansion</p>
              <p>· On-boarding influencers</p>
              <p>· On-boarding VC </p>
              <p>· Raise seed funds</p>
              <p>· Cross-platform integration</p>
              <p>· Release NFTs 2.0</p>
            </div>
          </div>
        </div>
        {/* <div className='col-md-4 col-8'>
          <div className='row'>
            <div className='col-md-2 col-2'>
              <img src='assets/group 24.svg' alt='' />
            </div>
            <div className='col-md-6 col-10 roadmap-bullets'>
              <h2 className=''>11/2021 ⚊ 2/2024</h2>
              <p>Project Concept</p>
              <p>Pitch Deck</p>
              <p>Website-Demo</p>
              <p>Airdrops</p>
              <p>Private Sales</p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
