/** @format */

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Faq from "react-faq-component";

const FAQs = () => {
  const data = {
    rows: [
      {
        title: " How does AquaTank works?",
        content: `AquaTank function by providing life-time yield to its token holders`,
      },
      {
        title: "How can I save from market volatility? ",
        content: ` We provide returns in USDT`,
      },
      {
        title: "Is it true that I can buy an NFT with joint ownership? ",
        content: `Yes, the Island NFT in AquaTank are fractional NFT that can be bought and owned jointly by different users at a time.`,
      },
      {
        title: "I want to buy Fish NFTs, how can I buy them? ",
        content: `You can buy 3D fist NFTs from our marketplace or you can use Island NFT ID to mint pets through Islands.`,
      },
      {
        title: "Does AquaTank have a game? ",
        content: `Yes AquaTank has a gaming mode, both in PVE and PVP mode; which are under progress right now and will be live after our launch.`,
      },
      // {
      //   title: "When is the Subscription of AquaTank? ",
      //   content: `The Subscription of AquaTank is scheduled on 17th August 2022. Please visit our social media handles for more updates.`,
      // },
    ],
  };

  const styles = {
    bgColor: "transparent",
    titleTextColor: "blue",
    rowTitleColor: "#000",
    rowContentColor: "#000",
    arrowColor: "#000",
    rowContentPaddingTop: "5px",
    rowContentPaddingBottom: "10px",
    rowContentPaddingLeft: "10px",
    rowContentPaddingRight: "10px",
    fontWeight: 600,
  };

  return (
    <>
      <Container fluid className='faqs ' id='FAQs'>
        <div className='container jumbotron pt-5  pb-5'>
          <div
            className='row roadmap text-center'
            data-aos='fade-up'
            data-aos-offset='500'
            data-aos-duration='1500'>
            <h1>FAQs </h1>

            <p className='m-auto text-center '>
              Frequently asked questions about Aqua Tank
            </p>
          </div>
          <Row className=' m-auto mt-4'>
            <Col lg={3}></Col>
            <Col lg={6}>
              <Faq data={data} styles={styles} />
            </Col>
            <Col lg={3}></Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default FAQs;
