/** @format */

import React from "react";
import { GiSwapBag } from "react-icons/gi";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

import {
  FaTwitter,
  FaDiscord,
  FaReddit,
  FaGithub,
  FaFacebook,
  FaInstagram,
  FaTelegramPlane,
  FaMediumM,
} from "react-icons/fa";
import CountDown from "../utils/Countdown";
export default function Hero() {
  const particlesInit = async main => {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(main);
  };
  const particlesLoaded = container => {
    console.log(container);
  };
  return (
    <div className='container-fluid hero-bg '>
      <Particles
        id='tsparticles'
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          fullScreen: {
            // "zIndex": 1
          },
          interactivity: {
            events: {
              onClick: {
                enable: true,
                mode: "repulse",
              },
              onHover: {
                enable: true,
                mode: "bubble",
              },
            },
            modes: {
              bubble: {
                distance: 400,
                duration: 0.3,
                opacity: 0.7,
                size: 4,
                divs: {
                  distance: 200,
                  duration: 0.4,
                  mix: false,
                  selectors: [],
                },
              },
              grab: {
                distance: 400,
                links: {
                  opacity: 0.5,
                },
              },
              repulse: {
                divs: {
                  distance: 200,
                  duration: 0.4,
                  factor: 100,
                  speed: 1,
                  maxSpeed: 50,
                  easing: "ease-out-quad",
                  selectors: [],
                },
              },
            },
          },
          particles: {
            links: {
              color: {
                value: "#ffffff",
              },
              distance: 500,
              opacity: 0.4,
              width: 2,
            },
            move: {
              attract: {
                rotate: {
                  x: 600,
                  y: 1200,
                },
              },
              direction: "bottom",
              enable: true,
              outModes: {
                bottom: "out",
                left: "out",
                right: "out",
                top: "out",
              },
            },
            number: {
              density: {
                enable: true,
              },
              value: 400,
            },
            opacity: {
              random: {
                enable: true,
              },
              value: {
                min: 0.1,
                max: 0.5,
              },
              animation: {
                speed: 1,
                minimumValue: 0.1,
              },
            },
            size: {
              random: {
                enable: true,
              },
              value: {
                min: 1,
                max: 10,
              },
              animation: {
                speed: 40,
                minimumValue: 0.1,
              },
            },
          },
        }}
      />
      <div className='container'>
        <a
          href='https://raydium.io/swap/?inputCurrency=sol&inputSymbol=9jaMLW&outputCurrency=9jaMLWLrKDzhNzRMipkpHHNj4xeH6ahmJ6jRHiamndF6&fixed=in'
          target='_blank'
          rel='noopener noreferrer'>
          <div className='row titles  text-center tm  '>
            <span className=' d-flex justify-content-center align-content-between align-items-center   text-dark'>
              <strong className='w-50 font-head pt-2 text-center d-flex gap-2 justify-content-center  align-items-center '>
                {" "}
                We are Live on Raydium
                <img
                  src='assets/Raydium.svg'
                  style={{ display: "inline-block", width: 24 }}
                  alt=''
                />
              </strong>{" "}
              {/* <CountDown /> */}
            </span>
          </div>
        </a>
        <div className='d-flex align-items-center gap-3 justify-content-center  pt-3'>
          <a
            href='https://raydium.io/swap/?inputCurrency=sol&inputSymbol=9jaMLW&outputCurrency=9jaMLWLrKDzhNzRMipkpHHNj4xeH6ahmJ6jRHiamndF6&fixed=in'
            target='_blank'
            rel='noopener noreferrer'>
            <img src='assets/ray.svg' width={180} alt='' />
          </a>
          <a
            href='https://www.dexview.com/solana/9jaMLWLrKDzhNzRMipkpHHNj4xeH6ahmJ6jRHiamndF6'
            target='_blank'
            rel='noopener noreferrer'>
            <img src='assets/dexview.svg' width={180} alt='' />
          </a>
        </div>
        <div className='row  pb-5 heroim'>
          <div className='col-md text-center'>
            <img
              src={"/assets/Logo.png"}
              alt=''
              className=' res-img hero-image'
              style={{ maxWidth: 450 }}
            />
            <h1 className='res-text w-100 '>
              Mint - Stake NFTs & Enjoy Lifetime Reward <br />{" "}
              contribute-to-the-environment
            </h1>

            <a
              href='https://raydium.io/swap/?inputCurrency=sol&inputSymbol=9jaMLW&outputCurrency=9jaMLWLrKDzhNzRMipkpHHNj4xeH6ahmJ6jRHiamndF6&fixed=in'
              target='_blank'
              rel='noopener noreferrer'>
              <button className='nav-btn mx-3 mt-2 tm'>
                <img
                  src='assets/Raydium.svg'
                  style={{ display: "inline-block", width: 24 }}
                  alt=''
                />{" "}
                &nbsp; Trade On Raydium
              </button>
            </a>
            {/* <a
              href='https://aquatank.xyz/'
              target='_blank'
              rel='noopener noreferrer'>
              <button className='nav-btn'>
                <GiSwapBag style={{ color: "#fff" }} color='#fff' /> Mint NFTs
              </button>
            </a> */}
            <a
              href='https://solscan.io/token/9jaMLWLrKDzhNzRMipkpHHNj4xeH6ahmJ6jRHiamndF6'
              target='_blank'
              rel='noopener noreferrer'>
              {" "}
              <div className='fixed-icons2 mt-3 m-auto'>
                <p>9jaMLWLrKDzhNzRMipkpHHNj4xeH6ahmJ6jRHiamndF6</p>
              </div>
            </a>
          </div>
        </div>
        <div className='col-md'>
          <div className='fixed-icons'>
            <a
              href='https://medium.com/@Aquatankio'
              target='_blank'
              rel='noopener noreferrer'>
              <FaMediumM size={25} />
            </a>
            <a
              href='https://twitter.com/Aquatankio'
              target='_blank'
              rel='noopener noreferrer'>
              <FaTwitter size={25} className='mt-3' />
            </a>
            <a
              href='https://t.me/aquatankio'
              target='_blank'
              rel='noopener noreferrer'>
              <FaTelegramPlane size={25} className='mt-3' />
            </a>
            <a
              href='https://discord.gg/zAjSvcXTug'
              target='_blank'
              rel='noopener noreferrer'>
              <FaDiscord size={25} className='mt-3' />
            </a>
            <a
              href='https://www.reddit.com/user/Aquatankio'
              target='_blank'
              rel='noopener noreferrer'>
              <FaReddit size={25} className='mt-3' />
            </a>
            <a
              href='https://github.com/Aquatankio'
              target='_blank'
              rel='noopener noreferrer'>
              <FaGithub size={25} className='mt-3' />
            </a>
            <a
              href='https://www.instagram.com/aquatankio/'
              target='_blank'
              rel='noopener noreferrer'>
              <FaInstagram size={25} className='mt-3' />
            </a>
            <a
              href='https://www.facebook.com/Aquatankio'
              target='_blank'
              rel='noopener noreferrer'>
              <FaFacebook size={25} className='mt-3' />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
