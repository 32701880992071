/** @format */

import React from "react";

export default function Tokenomics() {
  return (
    <div
      className='container-fluid section-padding tokenomics-bg'
      id='tokenomics'>
      <div className='row'>
        <div className='col-md'>
          <div className='sec4-bg res-img'>
            <img src={"/assets/Logo.png"} alt='' className='sec4-img res-img' />
            <div>
              <img src={"/assets/title.svg"} alt='' className='res-img' />
              <p>
                Aqua Tank Tokens is an exclusive currency that makes every
                player <br /> an investor, start making money and grow as
                AquaTank groes.
              </p>
              <h2>Total Supply</h2>
              <h1>10,000,000,000</h1>
            </div>
            <div className='table-responsive'></div>

            <div className='table-responsive'>
              <table className='table table-striped res-img'>
                <thead>
                  <tr className='table-light'>
                    <th scope='col'>Round</th>
                    <th scope='col'></th>
                    <th scope='col'></th>
                    <th scope='col'></th>
                    <th scope='col'>Percent(%)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Presale</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>55.24%</td>
                  </tr>
                  <tr>
                    <td>Liquidity</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>26.76%</td>
                  </tr>
                  <tr>
                    <td>Staking</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>10%</td>
                  </tr>
                  <tr>
                    <td>Airdrop</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>5%</td>
                  </tr>
                  <tr>
                    <td>CEX Listing</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>3%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
