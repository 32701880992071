/** @format */

import React from "react";
import { FiDatabase } from "react-icons/fi";
export default function Header() {
  return (
    <nav className='navbar navbar-expand-lg '>
      <div className='container-fluid'>
        <a className='navbar-brand' href='/'>
          <img src={"/assets/header-logo.png"} alt='' width={120} />
        </a>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'>
          <span className='navbar-toggler-icon'></span>
        </button>
        <div className='collapse navbar-collapse' id='navbarSupportedContent'>
          <ul className='navbar-nav m-auto mb-2 mb-lg-0'>
            <li className='nav-item'>
              <a className='nav-link active' aria-current='page' href='/'>
                Home
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link'
                rel='noopener noreferrer'
                href='https://aqua-tank.gitbook.io'
                target='_blank'>
                <strong>Document</strong>{" "}
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link'
                rel='noopener noreferrer'
                href='https://blocksafu.com/audit-solana/9jaMLWLrKDzhNzRMipkpHHNj4xeH6ahmJ6jRHiamndF6'
                target='_blank'>
                Audit
              </a>
            </li>
            {/* <li className='nav-item'>
              <a className='nav-link' href="https://.aquatank.xyz/" target="_blank" >
                Marketplace
              </a>
            </li> */}
            {/* <li className='nav-item'>
              <a className='nav-link' href='https://.aquatank.xyz/' target='_blank'>
                Dashboard
              </a>
            </li> */}
            {/* <li className='nav-item'>
              <a className='nav-link' href='https://.aquatank.xyz/'  target='_blank'>
                Governance
              </a>
            </li>
          */}
            <li className='nav-item'>
              <a
                className='nav-link'
                href='https://stake.aquatank.xyz/'
                rel='noopener noreferrer'
                target='_blank'>
                Stake
              </a>
            </li>
          </ul>
          <a
            href='https://raydium.io/swap/?inputCurrency=sol&inputSymbol=9jaMLW&outputCurrency=9jaMLWLrKDzhNzRMipkpHHNj4xeH6ahmJ6jRHiamndF6&fixed=in'
            target='_blank'
            rel='noopener noreferrer'>
            <button className='nav-btn'>Buy $Aqua</button>
          </a>
        </div>
      </div>
    </nav>
  );
}
