/** @format */

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Carousal = props => {
  return (
    <div className='container-fluid carousal'>
      <div className='row text-center'>
        <p>
          Following features of the AquaTank are all functioning cordially{" "}
          <br /> to operate in the best interest of our user base:
        </p>
      </div>
      <div className='row'>
        {/* <Carousel
            responsive={responsive}
            swipeable={false}
            draggable={false}
           
            arrows={true}
            ssr={true} // means to render carousel on server-side.
            // infinite={true}
            // autoPlay={props.deviceType !== "mobile" ? true : false}
            // autoPlaySpeed={2000}
            keyBoardControl={true}
            customTransition='all .5'
            transitionDuration={500}
            containerClass='carousel-container'
            removeArrowOnDeviceType={["tablet", "mobile"]}
            deviceType={props.deviceType}> */}
                 <div className='col-md-4 '>
          <div className='card-bg m-auto'>
            <div className='card-body'>
              <div className='text-center pt-3 p-3'>
                <img
                  src={"/assets/swords 2.svg"}

                  alt=''
                />
                <h3 >Referral Program</h3>
                <p>Players can use eachother;s referral links to redeem rewards</p>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='card-bg m-auto'>
            <div className='card-body'>
              <div className='text-center pt-3 p-2  '>
                <img
                  src={
                    "/assets/22_nft_staking.svg"
                  }
                  width={100}
                  alt=''
                />
                <h3>NFTs Staking</h3>
                <p>
                  The NFT staking protocols, such as Islands, can help you
                  generate higher than usual APYs{" "}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className='col-md-4'>
          <div className='card-bg m-auto'>
            <div className='card-body'>
              <div className='text-center pt-3 p-2'>
                <img
                  src={"/assets/stake.png"}
                  width={100}
                 
                  alt=''
                />
                <h3>RFI</h3>
                <p>
                  Our frictionless yield generation help you to earn passively
                  without any major hurdles; for life time.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-4 mt-3'>
          <div className='card-bg m-auto'>
            <div className='card-body'>
              <div className='text-center pt-3 p-2'>
                <img
                  src={"/assets/joystick.png"}
                  alt=''  width={80}
                />
                <h3>Multiverse Gaming </h3>
                <p>The multiverse gaming feature allows players to play the game with NFT holding
</p>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-4 mt-3'>
          <div className='card-bg m-auto'>
            <div className='card-body'>
              <div className='text-center pt-3 p-2'>
                <img
                  src={"/assets/logo.png"}
                  alt=''
                  width={100}
                />
                <h3>Marketplace</h3>
                <p>
                  The offline marketplace of AquaTank is full of suprising 3D
                  NFT collections.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className='col-md-4 mt-3'>
          <div className='card-bg m-auto'>
            <div className='card-body'>
              <div className='text-center pt-3 p-2'>
                <img
                  src={"/assets/joystick.png"}
                  alt=''
                  width={80}
                />
                <h3>Governance</h3>
                <p>
                  All the rest of our features are maintained through a
                  transparent and inclusive governance model
                </p>
              </div>
            </div>
          </div>
        </div>

   
        {/* </Carousel> */}
      </div>
    </div>
  );
};

export default Carousal;
