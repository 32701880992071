/** @format */

import React from "react";
import Carousel from "react-multi-carousel";

const Slider2 = props => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const island = [
    { imgurl: "assets/nfts/178.png", id: "#1001" },
    { imgurl: "assets/nfts/90.png", id: "#1002" },
    { imgurl: "assets/nfts/159.png", id: "#1003" },
    { imgurl: "assets/nfts/189.png", id: "#1004" },
    { imgurl: "assets/nfts/8.png", id: "#1006" },
    { imgurl: "assets/nfts/45.png", id: "#1007" },
    { imgurl: "assets/nfts/99.png", id: "#1008" },
    { imgurl: "assets/nfts/107.png", id: "#1009" },
    { imgurl: "assets/nfts/102.png", id: "#1001" },
    { imgurl: "assets/nfts/10.png", id: "#1002" },
    { imgurl: "assets/nfts/178.png", id: "#1001" },
    { imgurl: "assets/nfts/90.png", id: "#1002" },
    { imgurl: "assets/nfts/159.png", id: "#1003" },
    { imgurl: "assets/nfts/189.png", id: "#1004" },
    { imgurl: "assets/nfts/8.png", id: "#1006" },
    { imgurl: "assets/nfts/45.png", id: "#1007" },
    { imgurl: "assets/nfts/99.png", id: "#1008" },
    { imgurl: "assets/nfts/107.png", id: "#1009" },
    { imgurl: "assets/nfts/102.png", id: "#1001" },
    { imgurl: "assets/nfts/10.png", id: "#1002" },
  ];
  return (
    <>
      {/* ---------------------------------------------------------------------------------------------- */}
      {/*                                           card Slider                                          */}
      {/* ---------------------------------------------------------------------------------------------- */}
      <div className='container'>
        <div className='row mt-3'>
          <Carousel
            autoPlaySpeed={1000}
            responsive={responsive}
            infinite={true}
            autoPlay={props.deviceType !== "mobile" ? true : false}
            arrows={false}
            customTransition='all 100s linear'>
            {island.map((v, i) => {
              return (
                <div className='col-md-3 ' key={i}>
                  <div className='card-bgs m-auto p-4'>
                    <div className='card-body'>
                      <div className='text-center '>
                        <h5>{v.id}</h5>
                      </div>

                      <div className='img-box'>
                        <img src={v.imgurl} className='w-100 d-block' alt='' />
                      </div>
                      <div className='text-center '>
                        <h3>{v.name}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default Slider2;
