/** @format */

import React from "react";
import Slider from "../utils/Slider";
import Slider2 from "../utils/Slider2";

const Sec1 = () => {
  return (
    <>
      <div className='container-fluid sec2-bg3'>
        <div className=''>
          <div className='row'>
            <div className='col-lg-4 m-auto'>
              <div>
                <h1>
                  Life-Time Reward <br /> based <span>NFTs</span>
                </h1>
              </div>
              <p className='mx-2 mt-3'>
                The AquaTank protocol yields consistent rewards throughout the
                duration of the token holding period for our users. To protect
                our users from the volatility of the crypto market, we provide
                rewards in USDT. Our frictionless yield generation assist in
                delivering hassle free consistent returns.{" "}
              </p>
              <div className='div my-5'>
                <img
                  src='assets/solanaLogo.svg'
                  className='w-75 m-auto'
                  alt=''
                />
              </div>
            </div>
            <div className='col-lg-8 fade-bg'>
              <Slider />
              <Slider2 />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sec1;
