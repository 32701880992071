/** @format */

import React from "react";
import { GiSwapBag, GiCardExchange } from "react-icons/gi";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import Carousal from "../utils/Carousal";

export default function HowToPlay() {
  return (
    <>
      <div className='container-fluid sec2-bg2 ' id='gameplay'>
        <div className='container'>
          <div className='row '>
            <div className='col-md-12'>
              <h1 className='res-text text-center'>
                Aqua <span>Features</span>
              </h1>
              <Carousal />
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid sec3-hero-bg pb-5'>
        <div className='row'>
          <div className='col-md '>
            <div className='text-center m-auto'>
              <img
                src={"assets/nft-nobg.png"}
                className='m-auto'
                width='100'
                alt=''
              />
              <h1 className='res-text' data-aos='fade-right'>
                <span>Life-time </span> reward generation
              </h1>
            </div>
            {/* <img
            src={process.env.REACT_APP_S3_URL + "/assets/4 Things.png"}
            alt=''
            className='res-img m-auto d-block'
          /> */}
            <div
              className='sec3-bg res-img'
              data-aos='fade-up'
              data-aos-offset='500'
              data-aos-duration='1000'>
              <div className='row'>
                <div className='col-md-4'>
                  <h2 className='res-text'>
                    <span className='sec3-span'>
                      Hold to Earn
                      <br />
                    </span>
                    Aqua Tank
                  </h2>
                </div>
                <div className='col-md-8 d-flex flex-wrap align-items-center'>
                  {/* <button className="btn create-btn" > Play Game</button> */}
                  <a
                    href='https://stake.aquatank.xyz/'
                    target='_blank'
                    rel='noopener noreferrer'>
                    {" "}
                    <button className='nav-btn'>
                      <GiSwapBag style={{ color: "#fff" }} color='#fff' /> Mint
                    </button>
                  </a>
                  <a
                    href='https://stake.aquatank.xyz/'
                    target='_blank'
                    without
                    rel='noreferrer'>
                    {" "}
                    <button className='nav-btn mx-3'>
                      <GiCardExchange style={{ color: "#fff" }} color='#fff' />{" "}
                      Stake Aqua
                    </button>
                  </a>
                  <a
                    href='https://vote.aquatank.xyz/'
                    target='_blank'
                    rel='noopener noreferrer'>
                    {" "}
                    <button className='nav-btn mx-5s'>
                      <AiOutlineAppstoreAdd
                        style={{ color: "#fff" }}
                        color='#fff'
                      />{" "}
                      Governance
                    </button>
                  </a>
                  {/* <img
                  src={process.env.REACT_APP_S3_URL + "/footer-1.svg"}
                  alt=''
                  className='mx-3'
                />
                <img
                  src={process.env.REACT_APP_S3_URL + "/footer-2.svg"}
                  alt=''
                /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
    </>
  );
}
