/** @format */

import React from "react";

export default function Partners() {
  return (
    <div className='container-fluid partners'>
      <div className='row '>
        <div className='partner-heading'>
          <h1 className='res-text' data-aos='fade-down'>
            Partners
          </h1>
          <p>We have great partners supporting this wonderful project.</p>
        </div>

        {/* <div className='col-lg-4'>
          <h3>
            Social <span style={{ color: "#eb00ff" }}>Partners</span>
          </h3>
          <div className='row'>
            <div className='col-md-3 col-6 partners-logo '>
              <a
                href='https://twitter.com/topgamefi/status/1557695015626739713'
                target='_blank'
                rel='noopener noreferrer'
                className=''>
                {" "}
                <img
                  src='assets/dSC4tr1B_400x400.jpg'
                  alt=''
                  className='res-img w-100 m-auto rond'
                />
              </a>
            </div>
            <div className='col-md-3 col-6 partners-logo '>
              <a
                href='https://twitter.com/Meta_Society_/status/1554401930734190594'
                target='_blank'
                rel='noopener noreferrer'>
                {" "}
                <img
                  src='assets/FlKtJqX9_400x400.jpg'
                  alt=''
                  className='res-img w-100 m-auto rond'
                />
              </a>
            </div>
            <div className='col-md-3 col-6 partners-logo '>
              <a
                href='https://twitter.com/Defi_Alliance/status/1552995634390609920'
                target='_blank'
                rel='noopener noreferrer'>
                {" "}
                <img
                  src='assets/KZ8Iy0MN_400x400.png'
                  alt=''
                  className='res-img w-100 m-auto rond'
                />
              </a>
            </div>
            <div className='col-md-3 col-6 partners-logo '>
              <a
                href='https://twitter.com/chainlink'
                target='_blank'
                rel='noopener noreferrer'>
                {" "}
                <img
                  src='assets/chainlink.png'
                  alt=''
                  className='res-img w-100 m-auto rond'
                />
              </a>
            </div>
          </div>
        </div> */}

        <div className='col-lg-4'>
          <h3>
            News <span style={{ color: "#eb00ff" }}>Partners</span>
          </h3>

          <div className='row'>
            <div className='col-md-4 col-6 partners-logo m-auto'>
              <a
                href='https://twitter.com/BSCNews/status/1558928982854205446'
                target='_blank'
                rel='noopener noreferrer'>
                {" "}
                <img
                  src='assets/EoB1ZU6C_400x400.jpg'
                  alt=''
                  className='res-img w-75 m-auto rond'
                />
              </a>
            </div>

            {/* <div className='col-md-4 col-6 partners-logo '>
              <a
                href='https://twitter.com/topbscnews/status/1558150191068237831'
                target='_blank'
                rel='noopener noreferrer'>
                {" "}
                <img
                  src='assets/eXC8fqOg_400x400.jpg'
                  alt=''
                  className='res-img w-75 m-auto rond'
                />
              </a>
            </div> */}
          </div>
        </div>

        {/* <div className='col-lg-4'>
          <h3>
            venture <span style={{ color: "#eb00ff" }}>capitalist</span>
          </h3>

          <div className='row'>
            <div className='col-md-4 col-6 partners-logo  m-auto'>
              <a
                href='https://twitter.com/venturesfunds/status/1555257205070262272'
                target='_blank'
                rel='noopener noreferrer'>
                {" "}
                <img
                  src='assets/F7SjaEX8_400x400.jpg'
                  alt=''
                  className='res-img w-75 m-auto rond '
                />
              </a>
            </div>
          </div>
        </div> */}

        <div className='col-lg-4'>
          <h3>
            LaunchPad <span style={{ color: "#eb00ff" }}>Partner</span>
          </h3>
          <div className='row'>
            <div className='col-md-4 col-6 partners-logo m-auto'>
              <a
                href='https://www.pinksale.finance/'
                target='_blank'
                rel='noopener noreferrer'>
                {" "}
                <img
                  src='assets/pinkmoon.png'
                  alt=''
                  className='res-img w-75 m-auto'
                />
              </a>
            </div>
          </div>
        </div>

        <div className='col-lg-4'>
          <h3>
            CEX <span style={{ color: "#eb00ff" }}>Partner</span>
          </h3>
          <div className='row'>
            <div className='col-md-4 m-auto col-6 partners-logo '>
              <a
                href='https://twitter.com/BitMartExchange/status/1557761029022556161'
                target='_blank'
                rel='noopener noreferrer'>
                {" "}
                <img
                  src='assets/fzK42aEg_400x400.png'
                  alt=''
                  className='res-img w-75 m-auto rond'
                />
              </a>
            </div>
          </div>
        </div>
        <div className='col-lg-4 mt-5 m-auto'>
          <h3>
            DEX <span style={{ color: "#eb00ff" }}>Partner</span>
          </h3>
          <div className='row'>
            <div className='col-md-4  col-6 partners-logo m-auto'>
              <a
                href='#!'
                // target='_blank'
                rel='noopener noreferrer'>
                {" "}
                <img
                  src='assets/Raydium.svg'
                  alt=''
                  className='res-img w-75  m-auto rounded-circle'
                />
              </a>
            </div>
            <div className='col-md-4  col-6 partners-logo m-auto'>
              <a href='#coming-soon' rel='noopener noreferrer'>
                {" "}
                <img
                  src='assets/ceto.jpg'
                  alt=''
                  className='res-img w-75 m-auto rond'
                />
              </a>
            </div>
          </div>
        </div>

        <div className='row partner-bottom-row text-center'>
          <h1 className='res-text'>
            Security <span style={{ color: "#eb00ff" }}>audit</span>
          </h1>
          <div className='col-md-2'></div>
          <div className='col-md-4'>
            <a
              href='https://blocksafu.com/audit-solana/9jaMLWLrKDzhNzRMipkpHHNj4xeH6ahmJ6jRHiamndF6'
              target='_blank'
              rel='noopener noreferrer'>
              {" "}
              <img
                src='assets/audit.svg'
                alt=''
                className='res-img w-75 '
              />{" "}
            </a>{" "}
          </div>
          <div className='col-md-4 col-6'>
            <a
              href='https://github.com/solidproof/projects/blob/main/AquaTank/SmartContract_Audit_Solidproof_AquaTank.pdf'
              target='_blank'
              rel='noopener noreferrer'>
              {" "}
              <img
                src='assets/solidproof1.png'
                alt=''
                className='res-img w-75'
              />
            </a>{" "}
          </div>

          <div className='col-md-2'></div>
        </div>
      </div>
    </div>
  );
}
