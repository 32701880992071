/** @format */

import "./App.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Base from "./components/layouts/Base";
import Home from "./pages/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";



function App() {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  });
  return (
    <>
      <Router>
        <Base>
          <Switch>
            <Route exact path='/'>
              <Home />
            </Route>
          </Switch>
        </Base>
      </Router>
    </>
  );
}

export default App;
