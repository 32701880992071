/** @format */

import React from "react";

const Videos = () => {
  return (
    <>
      <div className='container-fluid vd-sec pt-5 pb-5'>
        <div className='container'>
        <h1 className='res-text'>reviews</h1>

          <div className='row mt-3' >
            <div className='col-lg-6'>
              <div className='vd-card w-100 p-2'>
                <iframe
                  width='100%'
                  height='315'
                  src='https://www.youtube.com/embed/iPKTCxfJJA0'
                  title='AquaTank Token PRESALE 100x Crypto 🐟 NFT Staking With Daily Rewards 🐢 Free NFT on Pinksale 💰'
                  frameborder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowfullscreen></iframe>
              </div>
            </div>
            <div className='col-lg-6 '>
              <div className='vd-card w-100 p-2'>
                <iframe
                 width='100%'
                 height='315'
                  src='https://www.youtube.com/embed/XOTzJZlD2kk'
                  title='AquaTank NFT Staking With Daily Rewards | Free NFT on Pinksale'
                  frameborder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Videos;
