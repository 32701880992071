/** @format */

import React from "react";
import Carousal from "../utils/Carousal";

export default function GamePlay() {
  return (
    <>
      <div className='container-fluid sec2-bg ' id='gameplay'>
        <div className='container'>
          <div className='row '>
            <div className='col-md-6'>
              <h1 className='res-text'>
                Our <span>Mission</span>
              </h1>
              <p>
                All AquaTank team members and community members hope and aim to
                put this project to use for the threatened marine life. Hence,
                one-third of the profit generated from this project will be
                distributed among partner NGOs, which are working for the
                preservation of the marine ecosystem. As there are many exotic
                species at the verge of extinction, this project will specially
                work for their preservation and safety.
              </p>
              <p>
                To participate in our prime cause of preserving the marine
                ecosystem, you can actively participate through donating.
              </p>
              <p>Donate whatever you can at our address:</p>
            </div>
            <div className='col-md-6 m-auto'>
              <div className='video-bg res-video-bg m-auto' data-aos='fade-up'>
                <h2>Aqua</h2>
                {/* <img
                  src={process.env.REACT_APP_S3_URL + "/assets/Vector.png"}
                  alt=''
                  width={320}
                  className='mx-5 mt-3'
                /> */}
                <iframe
                  width='80%'
                  height='180'
                  mute={true}
                  src='https://www.youtube.com/embed/L7d_gJdCUPE?autoplay=1&rel=0&controls=0&showinfo=0'
                  title='Aqua Tank NFTs Staking'
                  frameborder='0'
                  className='mx-5 mt-3'
                  allow='accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowfullscreen></iframe>
              </div>
              <div className='row'>
                <div className='col-sm-6 m-auto'>
                  <a
                    href='https://github.com/solidproof/projects/blob/main/AquaTank/SmartContract_Audit_Solidproof_AquaTank.pdf'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <img
                      src='assets/solidproof.png'
                      className='w-75 d-block m-auto mt-3'
                      alt=''
                    />
                  </a>
                </div>
                {/* <div className='col-sm-6'>
                  <a
                    href=''
                    target='_blank'
                    rel='noopener noreferrer'>
                    <img src='assets/shield.png' className='w-25 ' alt='' />{" "}
                    <span className='safu2'>SafuAudit</span>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
